<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {getListingApi} from '@/api/listing'

/**
 * User list component
 */
export default {
  page: {
    title: "Active Listing",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Active List",
      items: [
        {
          text: "Listing",
          href: "/",
        },
        {
          text: "Active List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "MLS",
          sortable: true,
        },
        {
          key: "Address",
          sortable: true,
        },
        {
          key: "Price",
          sortable: true,
        },
        {
          key: "StartDate",
          sortable: true,
        },
        {
          key: "ExpiryDate",
          sortable: true,
        },
        {
          key: "Agent",
          sortable: true,
        },
        {
          key: "Vin",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
      coinvalue: "",
      statusValue: "",
      typeValue: null,
      datepickervalue: "",
      showModal: false,
      agent: {
        name: "",
        designation: "",
        email: "",
        file: "",
      },
      submitted: false,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList(p) {
      getListingApi().active_list({page : {page : p, page_size: this.perPage}}).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push({
            id: o.id,
            display_id : o.display_id,
            address :o.full,
            price : o.original_price_cad,
            start_date : o.listing_date,
            expiry_date : o.listing_expire,
            agent : {
              name : o.full_name,
              vin : o.vin
            },
          
            isSelected: false
          })
          this.totalRows = res.page.total;
         
        })  

      })
    },

    pageChange(p) {
      this.queryList(p)
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


  },
  validations: {
    contact: {
      name: { required },
      designation: { required },
      email: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Active Listing
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >



                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  class="table-check"
                >



                  <template #cell(MLS)="data">
                    <router-link
                      to="/listing/edit"
                      class="text-body fw-medium"
                      >{{ data.item.display_id}}</router-link
                    >
                  </template>
                  <template #cell(Address)="data"> 
                   {{ data.item.address }}
                  </template>

                  <template #cell(Price)="data">
                    ${{ Number(data.item.price).toLocaleString() }}
                  </template>

                  <template #cell(StartDate)="data">
                    {{ data.item.start_date }}
                  </template>
                  <template #cell(ExpiryDate)="data">
                    {{ data.item.expiry_date }}
                  </template>
                  <template #cell(Agent)="data">
                    {{ data.item.agent.name }}
                  </template>
                  <template #cell(Vin)="data">
                    {{ data.item.agent.vin }}
                  </template>

                  <template #cell(action)>
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item>Reject</b-dropdown-item>
                      <b-dropdown-item>Process</b-dropdown-item>
                      <b-dropdown-item>Approve</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
